import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'kk-confirm-dialog',
	standalone: true,
	template: ` <div
			class="modal-header p-3 pb-3 d-flex justify-content-between"
			data-testid="confirm-dialog-header"
		>
			<h2
				class="modal-title"
				id="modal-basic-title"
				data-testid="confirm-dialog-title"
				i18n
			>
				{{ TitleText }}
			</h2>
			<button
				type="button"
				class="btn kk-btn-transparent"
				i18n-aria-label
				(click)="activeModal.dismiss('Cross click')"
				data-testid="confirm-dialog-close"
			>
				<i class="fa-light fa-xmark"></i>
			</button>
		</div>
		<div class="modal-body px-3 py-0" data-testid="confirm-dialog-content">
			<p i18n>{{ ContentText }}</p>
		</div>
		<div class="modal-footer px-3 pb-2" data-testid="confirm-dialog-footer">
			<button
				type="button"
				class="btn btn-secondary"
				(click)="activeModal.close('Close click')"
				data-testid="confirm-dialog-cancel"
				i18n
			>
				{{ CancelText }}
			</button>
			<button
				type="submit"
				class="btn btn-success"
				(click)="confirm_click()"
				data-testid="confirm-dialog-ok"
				i18n
				ngbAutofocus
			>
				{{ OkText }}
			</button>
		</div>`,
})
export class ConfirmDialogComponent {
	@Input() TitleText: string
	@Input() ContentText: string
	@Input() public onConfirm: () => void
	@Input() public onCancel: () => void

	constructor(public activeModal: NgbActiveModal) {}

	// Texts
	CancelText = $localize`Peruuta`
	OkText = $localize`Ok`

	confirm_click() {
		if (this.onConfirm) {
			this.onConfirm()
		}
		this.activeModal.close()
	}

	cancel_click() {
		if (this.onCancel) {
			this.onCancel()
		}
		this.activeModal.close()
	}
}
