import { CommonModule } from '@angular/common'
import { Component, OnInit, signal, WritableSignal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { ReactiveFormsModule } from '@angular/forms'
// import { MatTableModule } from '@angular/material/table'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { map, Observable } from 'rxjs'
import { EventTypeService } from 'src/app/core/ngrx-store/entity-services'
import { EventType } from 'src/app/core/ngrx-store/models'
import { GroupHistoryToPastEventType } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history-mapping.model'

@Component({
	imports: [CommonModule, FormlyModule, ReactiveFormsModule],
	standalone: true,
	selector: 'kk-worktime-group-change-to-past-event-types',
	template: `
		<div class="overflow-auto">
			<table class="mat-elevation-z8 w-100">
				<thead>
					<tr>
						<th i18n>Työvuoro</th>
						<th i18n>Uusi työvuoro</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of this.items">
						<td>
							{{ getEventTypeName(item.oldEventTypeId) }}
						</td>
						<td>
							<div class="select-container">
								<select
									id="event-type-select"
									class="form-select"
									#eventTypeSelect
									(change)="
										onSelectedEventTypeChange(
											item.oldEventTypeId,
											eventTypeSelect.value
										)
									"
									[ngClass]="{ 'border-danger': item.newEventTypeId === 0 }"
								>
									<option [value]="0" [selected]="item.newEventTypeId === 0">
										<i>Valitse kirjauslaji</i>
									</option>
									<option
										*ngFor="let eventType of newEventTypes$ | async"
										[value]="eventType.id"
										[selected]="item.newEventTypeId === eventType.id"
									>
										{{ eventType.name }}
									</option>
								</select>
								<span
									class="warning-icon"
									*ngIf="item.newEventTypeId === 0"
									aria-hidden="true"
									>&#9888;</span
								>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	`,
})
export class WorktimeGroupChangeToPastEventTypes
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	constructor(private eventTypeService: EventTypeService) {
		super()
	}

	eventTypes = toSignal(this.eventTypeService.entities$)
	items: GroupHistoryToPastEventType[]
	newEventTypes$: Observable<EventType[]>

	ngOnInit(): void {
		this.items = this.props['items']

		this.newEventTypes$ = this.eventTypeService.entities$.pipe(
			map((eventType) =>
				eventType.filter(
					(et) =>
						et.worktimeGroups.length === 0 ||
						et.worktimeGroups.includes(this.props['newGroupId'])
				)
			)
		)
	}

	getEventTypeName(eventTypeId: number) {
		return this.eventTypes()?.find((et) => et.id === eventTypeId)?.name
	}

	onSelectedEventTypeChange(oldEventTypeId: number, eventTypeId: string) {
		const eventTypeSelection = this.items.find(
			(ws) => ws.oldEventTypeId === oldEventTypeId
		)

		if (eventTypeSelection)
			eventTypeSelection.newEventTypeId = parseInt(eventTypeId)
	}
}
