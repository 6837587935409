<div class="grid gap-3 mb-4">
	<h3 class="g-col-12 fw-bold">{{header}}</h3>
	<div
		class="g-col-12"
		*ngFor="let workshiftWorktimeReasons of this.workshiftsWorktimeReasons "
	>
		<div>
			<label
				class="form-label"
				[for]="'select-' + workshiftWorktimeReasons.workShift.id"
				>{{ workshiftWorktimeReasons.worktimeGroup?.name }} / {{
				workshiftWorktimeReasons.workShift.name }}</label
			>
			<mat-select
				id="select-{{ workshiftWorktimeReasons.workShift.id }}"
				class="form-select"
				[formControl]="getForm(workshiftWorktimeReasons.workShift.id) || createFormControl(workshiftWorktimeReasons.workShift.id)"
				(selectionChange)="updateModelEntries(workshiftWorktimeReasons.workShift.id, $event.value)"
			>
				<mat-option
					#option
					*ngFor="let reason of workshiftWorktimeReasons.worktimeReasons"
					[value]="reason.reasonId"
				>
					<div class="d-flex gap-2 align-items-center">
						<span>{{ reason.description }}</span>
					</div>
				</mat-option>
			</mat-select>
		</div>
	</div>
</div>
