import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { MbscDatepickerModule } from '@mobiscroll/angular'
import { timezonePlugin } from 'src/app/shared/plugins/timezone.plugin'
import { ReactiveFormsModule } from '@angular/forms'

@Component({
	standalone: true,
	imports: [FormlyModule, MbscDatepickerModule, ReactiveFormsModule],
	selector: 'kk-datetime-picker',
	template: `
		<div class="input-group">
			<button
				class="btn border border-1 border-neutral-30"
				(click)="datepicker.open()"
				[disabled]="(this.field.props && this.field.props['disabled']) || false"
				type="button"
			>
				<i class="fa-light fa-calendar-clock"></i>
			</button>

			<mbsc-datepicker
				class="form-control"
				[formControl]="formControl"
				[formlyAttributes]="field"
				[attr.data-testid]="this.props.attributes?.['data-testid']"
				[controls]="
					this.field.props['controls']
						? this.field.props['controls']
						: ['datetime']
				"
				[showOnClick]="!this.field.props['disabled']"
				[showOnFocus]="!this.field.props['disabled']"
				[showInput]="!this.field.props['disabled']"
				[dateFormat]="this.field.props['dateFormat']"
				[returnFormat]="this.field.props['returnFormat']"
				[min]="field.props.min"
				[max]="field.props.max"
				[timezonePlugin]="timezonePlugin"
				dataTimezone="utc"
				displayTimezone="local"
				#datepicker
			></mbsc-datepicker>
		</div>
	`,
})
export class DateTimePickerComponent extends FieldType<FieldTypeConfig> {
	timezonePlugin = timezonePlugin
}
