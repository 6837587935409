import { CommonModule } from '@angular/common'
import { Component, ChangeDetectionStrategy, Input } from '@angular/core'
import { SidebarItem } from '../../models'
import { SkeletonLoaderComponent } from 'src/app/shared/components/skeleton/skeleton-loader.component'

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [CommonModule],
	selector: 'kk-management-entity-list-item',
	template: `
		<div class="py-4 px-3">
			<p class="--bold">
				{{ item.title | async }}
			</p>
			<p class="text-secondary">
				{{ item.subtitle | async }}
			</p>
			<ng-container *ngFor="let field of item.fields$">
				<p class="text-secondary-emphasis">{{ field | async }}</p>
			</ng-container>
		</div>
	`,
})

/**
 * Skeleton component that can be displayed when items are loading
 */
export class EntityListItemComponent {
	@Input() item: SidebarItem
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [CommonModule, SkeletonLoaderComponent],
	selector: 'kk-management-entity-list-item-skeleton',
	template: `
		<div style="min-height: 120px;" class="d-flex flex-column w-100 py-2 px-3">
			<kk-skeleton-loader type="text" width="50%" class="mb-4 mt-1" />

			<kk-skeleton-loader type="text" width="75%" />
			<kk-skeleton-loader type="text" width="75%" />
		</div>
	`,
})
export class EntityListItemSkeletonComponent {}
