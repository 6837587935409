import { inject, InjectionToken, Injectable } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import {
	EntityService,
	EntityType,
	SidebarItem,
	entitySelectorFactory,
} from '../../models'
import {
	autoPunchOutPracticeLabels,
	WorkShift,
} from 'src/app/core/ngrx-store/workshift/workshift.model'
import { WorkShiftService } from 'src/app/core/ngrx-store/workshift/workshift.service'
import { workShiftSelector } from 'src/app/core/ngrx-store/workshift/workshift.selectors'
import { map, of } from 'rxjs'
import {
	EventTypeService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { selectRouterEntityTypePath } from 'src/app/core/ngrx-store/router/router.selectors'
import {
	emptyRow,
	checkboxField,
	numberField,
	requiredTextField,
} from 'src/app/shared/components/form/formly-field'
import { WorkShiftCreateNewContentComponent } from './workshift-create-new.component'
import {
	DateSelectionType,
	DateSelectionTypeLabels,
} from 'src/app/core/utils/date-selection-type'
import { dayOfWeekLabels } from 'src/app/core/utils/day-of-week'
import { SpecialDayTypeLabels } from 'src/app/core/utils/special-day-type'
import {
	OvertimePractice,
	overtimePracticeOptions,
} from 'src/app/core/ngrx-store/worktime-group/overtime-practice.model'
import { DateUtils } from 'src/app/core/utils/date-utils'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'

export const selectWorkShiftEntity = entitySelectorFactory(workShiftSelector)

const lunchReductionSettingLabels = {
	NoReduction: $localize`Ei automaattista vähennystä`,
	AtleastMinimum: $localize`Ruokatunti vähintään minimimäärä`,
	AtleastMinimumOrPenalty: $localize`Ruokatuntivähennys tai minimimäärä`,
}

const lunchReductionPositionLabels = {
	Time: $localize`Tiettyyn kellonaikaan`,
	MinutesFromWorkShiftStart: $localize`Tietty tuntimäärä työvuoron suunitellusta alkuajasta`,
	MinutesFromFirstStamp: $localize`Tietty tuntimäärä ensimmäisen sisäänleimauksen jälkeen`,
}

const PriorityLabels = {
	Normal: $localize`5 - Normaali`,
	ExceptionDays: $localize`4 - Pyhäpäivät ym.`,
	ShortenedDays: $localize`3 - Lyhennetyt päivät`,
	OtherExceptions: $localize`2 - Muut poikkeukset`,
	Other: $localize`1 - Kaikki muut ohittavat`,
}

@Injectable({ providedIn: 'root' })
export class WorkShiftEntityService extends EntityService<WorkShift> {
	entityTypePath = this.entityCollectionService.store.selectSignal(
		selectRouterEntityTypePath
	)

	constructor(
		workShiftService: WorkShiftService,
		private worktimeGroupService: WorktimeGroupService,
		private eventTypeService: EventTypeService
	) {
		super(workShiftService, selectWorkShiftEntity)

		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: {
							label: $localize`Perustiedot`,
						},
						fieldGroupClassName: 'grid',
						fieldGroup: [
							requiredTextField('name', $localize`Nimi`, 'g-col-12'),
							{
								key: 'worktimeGroupId',
								type: 'select',
								className: 'g-col-12',
								props: {
									required: true,
									label: $localize`Työaikaryhmä`,
									options: this.worktimeGroupService.entities$.pipe(
										map((worktimeGroups) =>
											worktimeGroups.map((group) => ({
												value: group.id,
												label: group.name,
											}))
										)
									),
									attributes: {
										'data-testid': 'management-workshift-worktime-group',
									},
								},
							},
							{
								key: 'defaultEventType',
								type: 'select',
								className: 'g-col-12',
								props: {
									required: true,
									label: $localize`Oletuskirjauslaji`,
									options: this.eventTypeService.entities$.pipe(
										map((eventTypes) => {
											return eventTypes
												.sort((a1, a2) =>
													a1.orderNumber > a2.orderNumber ? 1 : -1
												)
												.map((eventType) => ({
													value: eventType.id,
													label: eventType.name,
												}))
										})
									),
									attributes: {
										'data-testid': 'management-workshift-default-event-type',
									},
								},
							},
							emptyRow,
							{
								key: 'startTime',
								type: 'time',
								className: 'g-col-12 g-col-lg-6',
								props: {
									label: $localize`Työvuoron alkuaika`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-start-time',
									},
								},
								expressions: {
									'props.required': (field: FormlyFieldConfig) => {
										return !field.model.normalDuration
									},
								},
							},
							{
								key: 'endTime',
								type: 'time',
								className: 'g-col-12 g-col-lg-6',
								props: {
									label: $localize`Työvuoron loppuaika`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-end-time',
									},
								},
								expressions: {
									'props.required': (field: FormlyFieldConfig) => {
										return !field.model.normalDuration
									},
								},
							},
							{
								key: 'normalDuration',
								type: 'time',
								className: 'g-col-12',
								props: {
									label: $localize`Työvuoron normaalikesto`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-normal-duration',
									},
								},
								expressions: {
									'props.required': (field: FormlyFieldConfig) => {
										return !field.model.startTime && !field.model.endTime
									},
								},
							},
							{
								key: 'workdayEnd',
								type: 'time',
								className: 'g-col-12',
								props: {
									label: $localize`Työvuorokauden päättymisaika`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-workday-end',
									},
								},
							},
							{
								key: 'automaticPunchOut',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Automaattinen ulosleimaus`,
									options: Object.entries(autoPunchOutPracticeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-workshift-automatic-punch-out',
									},
								},
							},
							emptyRow,
							checkboxField('defaultWorkShift', $localize`Oletustyövuoro`),
						],
					},
					{
						props: { label: $localize`Liukumat` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								key: 'flextimeBegin',
								type: 'time',
								className: 'g-col-12',
								props: {
									label: $localize`Liukuman alkuaika`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-flextime-begin',
									},
								},
							},
							{
								key: 'flextimeEnd',
								type: 'time',
								className: 'g-col-12',
								props: {
									label: $localize`Liukuman loppuaika`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-flextime-end',
									},
								},
							},
							checkboxField(
								'flextimeEnabledOnOvertime',
								$localize`Liukuma-aikarajat käytössä myös ylitöissä`
							),
							emptyRow,
							{
								key: 'flextimeMaxMinutes',
								type: 'time',
								className: 'g-col-12',
								props: {
									label: $localize`Työvuoron maksimiliukuma`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-flextime-max-minutes',
									},
								},
							},
						],
					},
					{
						props: { label: $localize`Ruokatunti ja tauot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Ruokatuntiasetukset`}</h2>
								<br>`,
							},
							{
								key: 'lunchReductionMethod',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Ruokatuntivähennystapa`,
									options: Object.entries(lunchReductionSettingLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid':
											'management-workshift-lunch-reduction-method',
									},
								},
							},
							{
								className: 'g-col-12',
								fieldGroupClassName: 'grid',
								fieldGroup: [
									{
										key: 'lunchReductionWorkShiftMinimumDuration',
										type: 'time',
										className: 'g-col-12',
										props: {
											label: $localize`Ruokatuntivähennyksen minimi päivänpituus`,
											type: 'number',
											timezone: false,
											attributes: {
												'data-testid':
													'management-workshift-lunch-reduction-workshift-minimum-duration',
											},
										},
									},
									{
										key: 'lunchMinimumDuration',
										type: 'time',
										className: 'g-col-12',
										props: {
											label: $localize`Ruokatunnin minimipituus`,
											type: 'number',
											timezone: false,
											attributes: {
												'data-testid':
													'management-workshift-lunch-minimum-duration',
											},
										},
									},
									{
										key: 'lunchReductionWithoutStampMinutes',
										type: 'time',
										className: 'g-col-12',
										props: {
											label: $localize`Ruokatuntivähennys silloin kun ei ruokatuntileimauksia`,
											type: 'number',
											timezone: false,
											attributes: {
												'data-testid':
													'management-workshift-lunch-reduction-without-stamp-minutes',
											},
										},
										expressions: {
											hide: (field: FormlyFieldConfig) =>
												field.model.lunchReductionMethod === 'AtleastMinimum',
										},
									},
									{
										key: 'lunchReductionPosition',
										type: 'select',
										className: 'g-col-12 g-col-lg-6',
										props: {
											label: $localize`Ruokatuntivähennyksen sijainti`,
											options: Object.entries(lunchReductionPositionLabels).map(
												([key, label]) => ({
													value: key,
													label,
												})
											),
											attributes: {
												'data-testid':
													'management-workshift-lunch-reduction-position',
											},
										},
									},
									{
										key: 'lunchStartTime',
										type: 'datetime',
										className: 'g-col-12 g-col-lg-6',
										props: {
											label: $localize`Aikamääritys`,
											controls: ['time'],
											dateFormat: 'HH:ss',
											returnFormat: 'iso8601',
											attributes: {
												'data-testid': 'management-workshift-lunch-start-time',
											},
										},
									},
									/*checkboxField(
										'lunchSkipSalaryRobot',
										$localize`Sivuuta lounaskirjaukset palkkarobotissa`
									),*/
								],
								expressions: {
									hide: (field: FormlyFieldConfig) =>
										field.model.lunchReductionMethod === 'NoReduction',
								},
							},
							{
								className: 'g-col-12 fw-bold',
								template: `
								<br>
								<h2>${$localize`Työaikaan kuuluvat tauot`}</h2>
								<br>`,
							},
							numberField(
								'breakMaxCount',
								$localize`Taukojen maksimimäärä`,
								$localize`kpl`
							),

							{
								key: 'breakMaxDuration',
								type: 'time',
								className: 'g-col-12',
								props: {
									label: $localize`Taukojen maksimimitta`,
									type: 'number',
									timezone: false,
									attributes: {
										'data-testid': 'management-workshift-break-max-duration',
									},
								},
							},
						],
					},
					{
						props: { label: $localize`Työvuorosuunnitelma` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								key: 'hasPlan',
								type: 'checkbox',
								className: 'g-col-12',
								props: {
									label: $localize`Suunnitelma käytössä`,
								},
							},
							{
								key: 'validityStartDate',
								type: 'datetime',
								className: 'g-col-12 g-col-lg-6',
								props: {
									label: $localize`Voimassa alkaen`,
									controls: ['date'],
									dateFormat: 'D.M.YYYY',
									returnFormat: 'iso8601',
									attributes: {
										'data-testid': 'management-workshift-validity-start-date',
									},
								},
								expressions: {
									hide: '!model.hasPlan',
								},
							},
							{
								key: 'validityEndDate',
								type: 'datetime',
								className: 'g-col-12 g-col-lg-6',
								props: {
									label: $localize`Voimassa päättyen`,
									controls: ['date'],
									dateFormat: 'D.M.YYYY',
									returnFormat: 'iso8601',
									attributes: {
										'data-testid': 'management-workshift-validity-end-date',
									},
								},
								expressions: {
									hide: '!model.hasPlan',
								},
							},
							{
								key: 'dateSelectionType',
								type: 'radio',
								className: 'g-col-12 g-col-lg-6',
								props: {
									label: $localize`Voimassaolo`,
									options: Object.entries(DateSelectionTypeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-workshift-date-selection-type',
									},
								},
								expressions: {
									hide: '!model.hasPlan',
								},
							},
							{
								key: 'dateSelection_Date',
								type: 'datetime',
								className: 'g-col-12 g-col-lg-6',
								props: {
									label: $localize`Valitse päivä`,
									controls: ['date'],
									dateFormat: 'D.M.YYYY',
									returnFormat: 'iso8601',
									attributes: {
										'data-testid': 'management-workshift-date-selection-date',
									},
								},
								expressions: {
									hide: (field: FormlyFieldConfig) =>
										!field.model.hasPlan ||
										field.model.dateSelectionType !==
											DateSelectionType.SingleDay,
								},
							},
							{
								key: 'dateSelection_Days',
								type: 'multicheckbox',
								className: 'g-col-6',
								props: {
									label: 'Valitse päivät',
									type: 'array',
									options: Object.entries(dayOfWeekLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-workshift-date-selection-days',
									},
								},
								expressions: {
									hide: (field: FormlyFieldConfig) =>
										!field.model.hasPlan ||
										field.model.dateSelectionType !==
											DateSelectionType.Weekdays,
								},
							},
							{
								key: 'dateSelection_SpecialDays',
								type: 'multicheckbox',
								className: 'g-col-6',
								props: {
									label: $localize`Valitse poikkeuspäivätyypit`,
									type: 'array',
									options: Object.entries(SpecialDayTypeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid':
											'management-workshift-date-selection-special-days',
									},
								},
								expressions: {
									hide: (field: FormlyFieldConfig) =>
										!field.model.hasPlan ||
										field.model.dateSelectionType !==
											DateSelectionType.SpecialDays,
								},
							},
							{
								key: 'priority',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Tärkeysjärjestys/tyyppi`,
									options: Object.entries(PriorityLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-workshift-priority',
									},
								},
								expressions: {
									hide: (field: FormlyFieldConfig) => !field.model.hasPlan,
								},
							},
							{
								key: 'overtimePracticeDefault',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Ylituntitapaoletus`,
									attributes: {
										'data-testid':
											'management-workshift-overtime-practice-default',
									},
								},
								expressions: {
									hide: '!model.hasPlan',
									'props.options': (field: FormlyFieldConfig) => {
										return this.worktimeGroupService.entities$.pipe(
											map((worktimeGroups) => {
												const worktimeGroup = worktimeGroups.find(
													(group) => group.id === field.model.worktimeGroupId
												)

												if (!worktimeGroup) {
													return []
												}

												return overtimePracticeOptions
													.filter((option) => {
														switch (option.value) {
															case OvertimePractice.Flex:
																return (
																	worktimeGroup.overtimePracticeFlex ||
																	worktimeGroup.overtimePracticeAll
																)
															case OvertimePractice.Paid:
																return (
																	worktimeGroup.overtimePracticePaid ||
																	worktimeGroup.overtimePracticeAll
																)
															case OvertimePractice.Vacation:
																return (
																	worktimeGroup.overtimePracticeVacation ||
																	worktimeGroup.overtimePracticeAll
																)
															case OvertimePractice.Ignore:
																return (
																	worktimeGroup.overtimePracticeIgnore ||
																	worktimeGroup.overtimePracticeAll
																)
															default:
																return false
														}
													})
													.map(({ value, label }) => ({
														value: value,
														label,
													}))
											})
										)
									},
								},
							},
						],
					},
					{
						props: { label: $localize`Muut` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							checkboxField(
								'notUsedInWorkShiftUtils',
								$localize`Ei käytössä työajan automaattivalinnassa`
							),
							emptyRow,
							/*textField(
								'payslipCode',
								$localize`Palkanlaskennan koodi`,
								'g-col-12'
							),*/
							{
								key: 'forcedDaysOfWeek',
								type: 'multicheckbox',
								className: 'g-col-12',
								props: {
									label: 'Viikonpäivärajoitus',
									type: 'array',
									options: Object.entries(dayOfWeekLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-workshift-forced-days-of-week',
									},
								},
							},
							{
								key: 'forcedExceptionDays',
								type: 'multicheckbox',
								className: 'g-col-12',
								props: {
									label: 'Poikkeuspäivärajoitus',
									type: 'array',
									options: Object.entries(SpecialDayTypeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-workshift-forced-exception-days',
									},
								},
							},
						],
					},
				],
			},
		]
	}

	allowedDaysText = $localize`Viikonpäivärajoitus (sallitut päivät)`

	// Need to check with the client/ designer what we should display here.
	override mapEntityToSidebarItem = (workShift: WorkShift): SidebarItem => {
		return {
			id: workShift.id.toString(),
			title: of(workShift.name),
			subtitle: this.worktimeGroupService.entities$.pipe(
				map((groups) => groups.find((g) => g.id === workShift.worktimeGroupId)),
				map((group) =>
					group !== undefined
						? $localize`Työaikaryhmä` + ': ' + group.name
						: $localize`Ei valittua työaikaryhmää`
				)
			),
			fields$: [
				of($localize`Kesto` + ': ' + this.getStartEndTimeText(workShift)),
			],
		}
	}

	getStartEndTimeText(workShift: WorkShift) {
		if (workShift.normalDuration) return workShift.normalDuration
		else if (workShift.startTime && workShift.endTime) {
			const lengthInMinutes = DateUtils.calculateTimeDifference(
				workShift.startTime,
				workShift.endTime,
				'minutes'
			)
			return (
				DateUtils.formatToTime(workShift.startTime, false) +
				' - ' +
				DateUtils.formatToTime(workShift.endTime, false) +
				' (' +
				DateUtils.minutesToHoursAndMinutes(lengthInMinutes) +
				')'
			)
		} else return ''
	}
}

export const workShiftEntityType: EntityType = {
	mainGroup: EntityTypeGroup.Worktime,
	title: $localize`Työvuorot`,
	explanation: $localize`Kaikki kirjaukset kirjataan Työvuoroille
	Valitse sivupalkista tarkasteltava tai muokattava työvuoro`,
	path: 'workshifts',
	serviceToken: new InjectionToken<WorkShiftEntityService>('workshifts', {
		factory: () => inject(WorkShiftEntityService),
	}),
	createNewContentComponent: WorkShiftCreateNewContentComponent,
}
