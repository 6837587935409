import { AdminType } from '../../ngrx-store/user/user.model'
import { adminTypeAllowManagement } from '../../utils/admin-rules'

export interface NavMenuOption {
	id: string
	title?: string
	link?: string
	icon?: string
	action?: () => void
	allowAccesAdminTypes?: AdminType[]
}

export const navMenuOptions: NavMenuOption[] = [
	{
		id: 'home-link',
		title: $localize`Etusivu`,
		link: '',
		icon: 'fa-regular fa-house-chimney',
	},
	{
		id: 'news-link',
		title: $localize`Ajankohtaista`,
		link: 'news',
		icon: 'fa-regular fa-newspaper',
	},
	{
		id: 'management-link',
		title: $localize`Hallinta`,
		link: 'management',
		icon: 'fa-regular fa-table-cells',
		allowAccesAdminTypes: adminTypeAllowManagement,
	},
	{
		id: 'calendar-link',
		title: $localize`Kalenteri`,
		link: 'calendar',
		icon: 'fa-regular fa-calendar-days',
	},
]
