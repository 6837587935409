import { inject, Injectable, InjectionToken } from '@angular/core'
import {
	entitySelectorFactory,
	EntityService,
	EntityType,
	SidebarItem,
} from '../../models'
import { Observable, of } from 'rxjs'
import { balanceLockSelector } from 'src/app/core/ngrx-store/balance-lock/balance-lock.selectors'
import { UrlMatchResult, UrlSegment } from '@angular/router'
import { BalanceLockCreateComponent } from './balance-lock-create.component'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'
import { BalanceLock } from 'src/app/core/ngrx-store/models'
import { BalanceLockService } from 'src/app/core/ngrx-store/entity-services'

export const selectBalanceLockEntity =
	entitySelectorFactory(balanceLockSelector)

@Injectable({ providedIn: 'root' })
export class BalanceLockEntityService extends EntityService<BalanceLock> {
	constructor(balanceLockService: BalanceLockService) {
		super(balanceLockService, selectBalanceLockEntity)
	}

	override mapEntityToSidebarItem = () => ({}) as SidebarItem

	override getAll(): Observable<BalanceLock[]> {
		return of([])
	}
}

export const balanceLock: EntityType = {
	title: $localize`Saldolukitukset`,
	explanation: $localize`Saldolukitukset`,
	path: 'balance-lock',
	serviceToken: new InjectionToken<BalanceLockEntityService>('balance-lock', {
		factory: () => inject(BalanceLockEntityService),
	}),
	createNewContentComponent: BalanceLockCreateComponent,
	useCustomView: true,
	hideDeleteButton: true,
	hideSubmitButton: true,
	mainGroup: EntityTypeGroup.General,
}
export function balanceLockUrlMatcher(
	url: UrlSegment[]
): UrlMatchResult | null {
	if (url[0].path === 'balance-lock' && url.length === 1) {
		return {
			consumed: url,
			posParams: {
				entityTypePath: new UrlSegment('balance-lock', {}),
			},
		}
	}
	return null
}
