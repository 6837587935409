import { CommonModule } from '@angular/common'
import { Component, OnInit, signal, WritableSignal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { ReactiveFormsModule } from '@angular/forms'
// import { MatTableModule } from '@angular/material/table'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { map, Observable } from 'rxjs'
import { WorkShiftService } from 'src/app/core/ngrx-store/entity-services'
import { WorkShift } from 'src/app/core/ngrx-store/models'
import { GroupHistoryToPastWorkShift } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history-mapping.model'

@Component({
	imports: [CommonModule, FormlyModule, ReactiveFormsModule],
	standalone: true,
	selector: 'kk-worktime-group-change-to-past-workshifts',
	template: `
		<div class="overflow-auto">
			<table class="mat-elevation-z8 w-100">
				<thead>
					<tr>
						<th i18n>Työvuoro</th>
						<th i18n>Uusi työvuoro</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of this.items">
						<td>
							{{ getWorkShiftName(item.oldWorkShiftId) }}
						</td>
						<td>
							<div class="select-container">
								<select
									id="workshift-select"
									class="form-select"
									#workshiftSelect
									(change)="
										onSelectedWorkshiftChange(
											item.oldWorkShiftId,
											workshiftSelect.value
										)
									"
									[ngClass]="{ 'border-danger': item.newWorkShiftId === 0 }"
								>
									<option [value]="0" [selected]="item.newWorkShiftId === 0">
										<i>Valitse työvuoro</i>
									</option>
									<option
										*ngFor="let workShift of newWorkShifts$ | async"
										[value]="workShift.id"
										[selected]="item.newWorkShiftId === workShift.id"
									>
										{{ workShift.name }}
									</option>
								</select>
								<span
									class="warning-icon"
									*ngIf="item.newWorkShiftId === 0"
									aria-hidden="true"
									>&#9888;</span
								>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	`,
})
export class WorktimeGroupChangeToPastWorkshifts
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	constructor(private workShiftService: WorkShiftService) {
		super()
	}

	workShifts = toSignal(this.workShiftService.entities$)
	items: GroupHistoryToPastWorkShift[]
	newWorkShifts$: Observable<WorkShift[]>

	ngOnInit(): void {
		this.items = this.props['items']

		this.newWorkShifts$ = this.workShiftService.entities$.pipe(
			map((workShifts) =>
				workShifts.filter(
					(ws) => ws.worktimeGroupId === this.props['newGroupId']
				)
			)
		)
	}

	getWorkShiftName(workShiftId: number) {
		return this.workShifts()?.find((ws) => ws.id === workShiftId)?.name
	}

	onSelectedWorkshiftChange(oldWorkshiftId: number, workshiftId: string) {
		const workShiftSelection = this.items.find(
			(ws) => ws.oldWorkShiftId === oldWorkshiftId
		)

		if (workShiftSelection)
			workShiftSelection.newWorkShiftId = parseInt(workshiftId)
	}
}
