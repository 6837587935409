import {
	worktimeGroupSelector,
	selectWorktimeGroupId,
} from 'src/app/core/ngrx-store/worktime-group/worktime-group.selectors'
import { EntityService, EntityType, entitySelectorFactory } from '../../models'
import { DimensionLevel, WorktimeGroup } from 'src/app/core/ngrx-store/models'
import { InjectionToken, inject, Injectable } from '@angular/core'
import { WorktimeGroupService } from 'src/app/core/ngrx-store/worktime-group/worktime-group.service'
import {
	OvertimePractice,
	overtimePracticeOptions,
} from 'src/app/core/ngrx-store/worktime-group/overtime-practice.model'
import {
	DimensionLevelService,
	EventTypeService,
	WorkShiftService,
} from 'src/app/core/ngrx-store/entity-services'
import { WorktimeGroupCreateNewContentComponent } from './worktime-group-create-new.component'
import {
	checkboxField,
	emptyRow,
	numberField,
	textField,
} from 'src/app/shared/components/form/formly-field'
import {
	dimensionDisplayModeLabels,
	workshiftSelectionPracticeLabels,
} from 'src/app/core/ngrx-store/worktime-group/worktime-group.model'
import { dayOfWeekLabels } from 'src/app/core/utils/day-of-week'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { map, of } from 'rxjs'
import { acceptanceModeLabels } from 'src/app/core/ngrx-store/worktime-group/worktime-group.model'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'

export const selectWorktimeGroupEntity = entitySelectorFactory(
	worktimeGroupSelector
)

@Injectable({ providedIn: 'root' })
export class WorktimeGroupEntityService extends EntityService<WorktimeGroup> {
	constructor(
		worktimeGroupService: WorktimeGroupService,
		private readonly workShiftService: WorkShiftService,
		private readonly eventTypeService: EventTypeService,
		private readonly dimensionLevelService: DimensionLevelService
	) {
		super(worktimeGroupService, selectWorktimeGroupEntity)

		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: { label: $localize`Perustiedot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								className: 'g-col-12',
								fieldGroupClassName: 'grid gap-0',
								fieldGroup: [
									textField('name', $localize`Nimi`, 'g-col-12'),
									textField('description', $localize`Kuvaus`, 'g-col-12'),
								],
							},
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Liukumat`}</h2>
								<br>`,
							},
							{
								className: 'g-col-12',
								fieldGroupClassName: 'grid gap-0',
								fieldGroup: [
									{
										key: 'flextimeEnabledForGroup',
										type: 'checkbox',
										className: 'g-col-12',
										props: {
											label: $localize`Liukumasaldot käytössä`,
											attributes: {
												'data-testid':
													'management-worktime-group-flextime-enabled',
											},
										},
									},
									{
										key: 'flextimeMaximumBalance',
										type: 'input',
										className: 'g-col-12',
										props: {
											label: $localize`Päivän liukumakatto`,
											addonLeft: {
												text: $localize`minuuttia`,
											},
											attributes: {
												'data-testid':
													'management-worktime-group-flextime-max-balance',
											},
										},
										parsers: [(value: any) => (value === '' ? null : value)],
										expressions: {
											hide: '!model.flextimeEnabledForGroup',
										},
									},
									{
										key: 'flextimeMinimumBalanceChange',
										type: 'input',
										className: 'g-col-12',
										props: {
											label: $localize`Päivän minimiliukumaraja`,
											addonLeft: {
												text: $localize`minuuttia`,
											},
											attributes: {
												'data-testid':
													'management-worktime-group-flextime-min-balance-change',
											},
										},
										parsers: [(value: any) => (value === '' ? null : value)],
										expressions: {
											hide: '!model.flextimeEnabledForGroup',
										},
									},
									{
										key: 'flextimeIgnoreNegativeChanges',
										type: 'checkbox',
										className: 'g-col-12',
										props: {
											label: $localize`Ei miinuksia liukumasaldoon`,
											attributes: {
												'data-testid':
													'management-worktime-group-flextime-ignore-negative-changes',
											},
										},
										expressions: {
											hide: '!model.flextimeEnabledForGroup',
										},
									},
									{
										key: 'flextimeIgnoreEmptyWorkshifts',
										type: 'checkbox',
										className: 'g-col-12',
										props: {
											label: $localize`Ei laskentaa tyhjille päiville`,
											attributes: {
												'data-testid':
													'management-worktime-group-flextime-ignore-empty-workshifts',
											},
										},
										expressions: {
											hide: '!model.flextimeEnabledForGroup | model.flextimeIgnoreNegativeChanges',
										},
									},
								],
							},
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Valittavissa olevat ylituntitavat`}</h2>
								<br>`,
							},
							{
								className: 'g-col-12',
								fieldGroupClassName: 'grid gap-0',
								fieldGroup: [
									checkboxField(
										'overtimePracticeAll',
										$localize`Valitse kaikki`
									),
									{
										className: 'g-col-12',
										fieldGroupClassName: 'grid gap-0',
										fieldGroup: overtimePracticeOptions.map(
											({ worktimeGroupKey, label }) => ({
												key: worktimeGroupKey,
												type: 'checkbox',
												className: 'g-col-12 g-col-lg-6',
												props: {
													label,
												},
												expressions: {
													'props.disabled': 'model.overtimePracticeAll',
													hide: (field: FormlyFieldConfig) => {
														if (
															(!field.model.flextimeEnabledForGroup &&
																field.key === 'overtimePracticeFlex') ||
															(field.model.flextimeEnabledForGroup &&
																field.key === 'overtimePracticeHourByHour')
														) {
															field.formControl?.setValue(false)
															return true
														}

														return false
													},
													checked: (field: FormlyFieldConfig) => {
														// Check flex/hourByHour according to flextimeEnabledForGroup
														if (field.model.overtimePracticeAll) {
															if (
																(field.model.flextimeEnabledForGroup &&
																	field.key === 'overtimePracticeFlex') ||
																field.key === 'overtimePracticePaid' ||
																field.key === 'overtimePracticeVacation' ||
																field.key === 'overtimePracticeIgnore' ||
																(!field.model.flextimeEnabledForGroup &&
																	field.key === 'overtimePracticeHourByHour')
															) {
																field.formControl?.setValue(true)
																field.formControl?.markAsTouched
															}
														}
													},
												},
											})
										),
									},
									{
										key: 'overtimePracticeDefault',
										type: 'select',
										className: 'g-col-6',
										props: {
											label: $localize`Ylituntitapaoletus`,
											required: true,
											attributes: {
												'data-testid':
													'management-worktime-group-overtime-practice-default',
											},
										},
										expressions: {
											'props.options': (field: FormlyFieldConfig) => {
												return (
													overtimePracticeOptions
														// Filter out unchecked options
														.filter((overtimePractice) => {
															if (
																!field.model[overtimePractice.worktimeGroupKey]
															) {
																field.model[
																	overtimePractice.worktimeGroupKey
																].formControl?.setValue(false)
																field.model[overtimePractice.worktimeGroupKey]
																	.formControl?.markAsTouched

																if (
																	field.model.overtimePracticeDefault ===
																	overtimePractice.value
																) {
																	// Set default value Flex/HourByHour, null if not available
																	if (
																		field.model.flextimeEnabledForGroup &&
																		field.model.overtimePracticeFlex
																	) {
																		field.formControl?.setValue(
																			OvertimePractice.Flex
																		)
																	} else if (
																		!field.model.flextimeEnabledForGroup &&
																		field.model.overtimePracticeHourByHour
																	) {
																		field.formControl?.setValue(
																			OvertimePractice.HourByHour
																		)
																	} else {
																		field.formControl?.setValue(null)
																	}
																}
																return false
															}

															return true
														})
														.map(({ value, label }) => ({
															value: value,
															label,
														}))
												)
											},
										},
									},
								],
							},
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Työvuoron hakutapojen järjestys`}</h2>
								<br>`,
							},
							{
								key: 'workshiftSelectionOrder',
								type: 'sortable-list',
								className: 'g-col-12',
								props: {
									label: $localize`järjestys`,
									items: Object.entries(workshiftSelectionPracticeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid':
											'management-worktime-group-workshift-selection-order',
									},
								},
							},
							checkboxField(
								'revalidateWorkshiftOnPunchOut',
								$localize`Työvuoron haku kirjaukselle uudestaan ulosleimauksen yhteydessä`
							),
							emptyRow,
							{
								key: 'automaticReadyCheck',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Kirjausten automaattinen valmismerkintä`,
									options: [
										{ value: null, label: $localize`Ei käytössä` },
										...Object.entries(dayOfWeekLabels).map(([key, label]) => ({
											value: key,
											label,
										})),
									],
									attributes: {
										'data-testid':
											'management-worktime-group-automatic-ready-check',
									},
								},
							},
							numberField(
								'breakMaxLength',
								$localize`Tauon maksimipituus`,
								$localize`minuuttia`
							),
						],
					},
					{
						props: { label: $localize`Asetukset` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							// Omaleimaus
							{
								className: 'g-col-12 fw-bold',
								template: `<br />
								<h2>${$localize`Omaleimaus`}</h2>
								<br />`,
							},
							checkboxField(
								'showStampInOutWidget',
								$localize`Omaleimauspääte käytössä`,
								`g-col-12`
							),
							{
								className: 'g-col-12',
								template: `<br />`,
							},
							checkboxField(
								'askHeaderOnStampIn',
								$localize`Kysy selitys sisäänleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askHeaderOnStampOut',
								$localize`Kysy selitys ulosleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askWorkshiftOnStampIn',
								$localize`Kysy työvuoroa sisäänleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askWorkshiftOnStampOut',
								$localize`Kysy työvuoroa ulosleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askEventTypeOnStampIn',
								$localize`Kysy kirjauslajia sisäänleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askEventTypeOnStampOut',
								$localize`Kysy kirjauslajia ulosleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askStampingReasonOnStampIn',
								$localize`Kysy leimaussyy sisäänleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),
							checkboxField(
								'askStampingReasonOnStampOut',
								$localize`Kysy leimaussyy ulosleimatessa`,
								`g-col-12 g-col-lg-6`,
								{
									expressions: {
										'props.disabled': '!model.showStampInOutWidget'
									}
								}
							),

							// Kirjaus
							{
								className: 'g-col-12 fw-bold',
								template: `<br />
								<h2>${$localize`Kirjaus`}</h2>
								<br />`,
							},
							checkboxField(
								'askHeaderOnEvent',
								$localize`Kysy selitys`,
								`g-col-12`
							),
							checkboxField(
								'askBillabilityOnEvent',
								$localize`Kysy laskutettavuus`,
								`g-col-12`
							),
							checkboxField(
								'askFreetextOnEvent',
								$localize`Kysy vapaateksti`,
								`g-col-12`
							),
							checkboxField(
								'askWorkshiftOnEvent',
								$localize`Kysy työvuoroa`,
								`g-col-12`
							),

							// Hyväksyntämallit
							{
								// Hyväksyntämallit
								className: 'g-col-12 fw-bold',
								template: `<br />
								<h2>${$localize`Hyväksyntä`}</h2>
								<br />`,
							},
							checkboxField(
								'readyCheckInUse',
								$localize`Valmismerkintä käytössä`,
								`g-col-12`
							),
							{
								key: 'acceptanceMode',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Käytössä oleva hyväksyntämalli`,
									options: Object.entries(acceptanceModeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-worktime-group-acceptance-mode',
									},
								},
							},
							{
								// Kohteet
								className: 'g-col-12 fw-bold',
								template: `<br />
								<h2>${$localize`Kohteet`}</h2>
								<br />`,
							},
							{
								key: 'dimensionDisplayMode',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Kohteiden näyttötapa`,
									options: Object.entries(dimensionDisplayModeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid':
											'management-worktime-group-dimension-display-mode',
									},
								},
							},
							{
								key: 'dimensionLevelsForStamp',
								type: 'multiselect',
								className: 'g-col-6',
								props: {
									label: $localize`Leimatessa näytettävät kohdetasot`,
									multiple: true,
									items: this.dimensionLevelService.entities$.pipe(
										map((dimensionLevelsForStamp: DimensionLevel[]) =>
											dimensionLevelsForStamp.map((dimensionLevelForStamp) => ({
												value: dimensionLevelForStamp.id,
												label: dimensionLevelForStamp.description,
											}))
										)
									),
									attributes: {
										'data-testid':
											'management-worktime-group-dimension-levels-for-stamp',
									},
								},
							},
							{
								key: 'dimensionLevelsForEvent',
								type: 'multiselect',
								className: 'g-col-6',
								props: {
									label: $localize`Kirjauksella näytettävät kohdetasot`,
									multiple: true,
									items: this.dimensionLevelService.entities$.pipe(
										map((dimensionLevelsForEvent: DimensionLevel[]) =>
											dimensionLevelsForEvent.map((dimensionLevelForEvent) => ({
												value: dimensionLevelForEvent.id,
												label: dimensionLevelForEvent.description,
											}))
										)
									),
									attributes: {
										'data-testid':
											'management-worktime-group-dimension-levels-for-event',
									},
								},
							},
						],
					},
					{
						props: { label: $localize`Työvuorot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								type: 'workshift-table',
								className: 'g-col-12',
								props: {
									items: this.workShiftService.workShiftsForWorktimeGroup$,
								},
							},
						],
					},
					{
						props: { label: $localize`Kirjauslajit` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								type: 'event-type-table',
								className: 'g-col-12',
								props: {
									items: this.eventTypeService.eventTypesForWorktimeGroup$,
								},
							},
						],
					},
				],
			},
		]
	}

	override mapEntityToSidebarItem = (worktimeGroup: WorktimeGroup) => {
		return {
			title: of(worktimeGroup.name),
			id: selectWorktimeGroupId(worktimeGroup).toString(),
			subtitle: of(worktimeGroup.description),
		}
	}
}

export const worktimeGroupEntityType: EntityType = {
	mainGroup: EntityTypeGroup.Person,
	title: $localize`Työaikaryhmät`,
	explanation: $localize`Kaikki henkilöt ryhmitellään työaikaryhmiin
	Valitse sivupalkista tarkasteltava tai muokattava työaikaryhmä`,
	path: 'worktime-groups',
	serviceToken: new InjectionToken<WorktimeGroupEntityService>(
		'worktime-groups',
		{
			factory: () => inject(WorktimeGroupEntityService),
		}
	),
	createNewContentComponent: WorktimeGroupCreateNewContentComponent,
}
