import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { Observable, of } from 'rxjs'
import { hasAdminAccess } from 'src/app/core/guards/admin-guard/admin.guard'
import { AdminType, User } from 'src/app/core/ngrx-store/user/user.model'

@Directive({
	selector: '[kkOperationsAdminGuard]',
	standalone: true,
})
export class OperationsAdminGuardDirective {
	private adminTypes: AdminType[] | undefined
	private user: Observable<User | undefined> = of(undefined)

	constructor(
		private template: TemplateRef<unknown>,
		private viewContainer: ViewContainerRef
	) {}

	@Input() set kkOperationsAdminGuard(adminTypes: AdminType[] | undefined) {
		this.adminTypes = adminTypes
		this.renderTemplate()
	}

	@Input() set kkOperationsAdminGuardUser(user: Observable<User | undefined>) {
		this.user = user
		this.renderTemplate()
	}

	private renderTemplate() {
		this.user.subscribe((user) => {
			if (!user) this.viewContainer.clear()
			else if (
				this.user &&
				this.adminTypes &&
				hasAdminAccess(this.adminTypes, user)
			) {
				this.viewContainer.clear()
				this.viewContainer.createEmbeddedView(this.template)
			} else {
				this.viewContainer.clear()
			}
		})
	}
}
