import {
	Component,
	EventEmitter,
	Output,
	Input,
	ViewChild,
	ViewContainerRef,
	OnInit,
	TemplateRef,
	AfterViewInit,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

/*
 * A reusable modal component.
 * Use NgbModal (service) to open modals.
 */
@Component({
	selector: 'kk-modal',
	standalone: true,
	imports: [CommonModule],
	template: `
		<ng-template #template>
			<div
				class="modal-header border-0 pb-1 pt-2 px-4 d-flex justify-content-between"
				data-testid="modal-header"
			>
				<h2
					class="modal-title content-title d-flex align-items-center gap-2"
					id="modal-basic-title"
				>
					<i
						*ngIf="icon"
						class="fa-regular mt-1"
						[ngClass]="icon ? icon : ''"
					></i>
					{{ title }}
				</h2>
				<div class="text-nowrap">
					<button
						type="button"
						class="btn kk-btn-transparent"
						aria-label="more information"
						data-testid="modal-information-button"
					>
						<i class="fa-light fa-circle-question"></i>
					</button>
					<button
						type="button"
						class="btn kk-btn-transparent"
						aria-label="Sulje"
						i18n-aria-label
						(click)="close()"
						data-testid="modal-close-button"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
			</div>
			<ng-content>
				<!-- Separate modal body and footer components should be used here -->
			</ng-content>
		</ng-template>
	`,
})
export class ModalComponent implements OnInit, AfterViewInit {
	@Input() title: string
	@Input() icon: string | undefined
	@Output() closeModal = new EventEmitter<void>()

	@ViewChild('template', { static: true }) template: TemplateRef<unknown>

	constructor(
		public activeModal: NgbActiveModal,
		private viewContainerRef: ViewContainerRef
	) {}

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template)
	}

	ngAfterViewInit(): void {
		this.viewContainerRef.element.nativeElement.remove()
	}

	close() {
		this.activeModal.dismiss('Cross click')
		this.closeModal.emit()
	}
}

// Intended to be used inside ModalComponent
@Component({
	selector: 'kk-modal-body',
	standalone: true,
	template: `
		<ng-template #template>
			<div
				style="min-height: 100px;"
				class="modal-body mb-3 py-0 px-4"
				data-testid="modal-body"
				aria-modal
			>
				<ng-content></ng-content>
			</div>
		</ng-template>
	`,
})
export class ModalBodyComponent implements OnInit, AfterViewInit {
	@ViewChild('template', { static: true }) template: TemplateRef<unknown>

	constructor(private viewContainerRef: ViewContainerRef) {}

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template)
	}

	ngAfterViewInit(): void {
		this.viewContainerRef.element.nativeElement.remove()
	}
}

// Intended to be used inside ModalComponent
@Component({
	selector: 'kk-modal-footer',
	standalone: true,
	template: `
		<div class="modal-footer border-0 pb-3 px-4">
			<ng-content></ng-content>
		</div>
	`,
})
export class ModalFooterComponent {}

// Intended to be used inside ModalComponent
@Component({
	selector: 'kk-modal-footer-default-content',
	standalone: true,
	imports: [CommonModule, ModalFooterComponent],
	template: `
		<kk-modal-footer data-testid="modal-footer">
			<button
				class="btn btn-secondary"
				(click)="close()"
				data-testid="modal-cancel-button"
				i18n
			>
				Peruuta
			</button>

			<button
				[class]="'btn ' + submitButtonVariant"
				[disabled]="submitButtonDisabled"
				(click)="onSubmit()"
				data-testid="modal-submit-button"
			>
				{{ submitButtonText }}
			</button>
		</kk-modal-footer>
	`,
})
export class ModalFooterWithDefaultButtonsComponent {
	@Output() submitModal = new EventEmitter<void>()
	@Output() closeModal = new EventEmitter<void>()
	@Input() submitButtonText = $localize`Ok`
	@Input() submitButtonDisabled: boolean
	@Input() submitButtonVariant: 'btn-success' | 'btn-danger' = 'btn-success'
	constructor(public activeModal: NgbActiveModal) {}

	onSubmit() {
		this.submitModal.emit()
	}

	close() {
		this.activeModal.dismiss('cancel')
		this.closeModal.emit()
	}
}
