@if (show()) {
	<div
		class="d-none d-lg-flex flex-row"
		data-testid="ManagementFormControlsHeader"
	>
		<div ngbDropdown display="dynamic" class="h-100 me-2">
			<button
				type="button"
				id="action-menu-toggle"
				ngbDropdownToggle
				class="d-flex bg-transparent gap-2 justify-content-center align-items-center border-0"
				[class.d-none]="actionMenuOptions().length === 0"
				[disabled]="!actionsActive()"
				data-testid="ManagementFormControlsActionsHeader"
				#toggle
			>
				<i class="fa-regular fa-ellipsis"></i>
			</button>
			<div
				ngbDropdownMenu
				[attr.aria-labelledby]="toggle.id"
				class="p-0 rounded-0 shadow-sm"
			>
				@for (
					option of actionMenuOptions();
					track option.title;
					let index = $index
				) {
					<button
						ngbDropdownItem
						(click)="onSelectAction(index)"
						[attr.data-testid]="'actionMenuOption' + index"
						class="p-2"
					>
						<p>
							{{ option.title }}
						</p>
					</button>
				}
			</div>
		</div>

		<button
			(click)="addEntity()"
			type="button"
			class="btn btn-secondary me-2"
			[disabled]="!newActive()"
			data-testid="ManagementFormControlsAddHeader"
		>
			<div class="d-flex flex-row align-items-center">
				<i class="fa-light fa-circle-plus pe-2"></i>
				<p i18n>Uusi</p>
			</div>
		</button>

		@if (showDelete()) {
			<button
				(click)="deleteEntity()"
				[disabled]="!deleteActive()"
				type="button"
				class="btn btn-secondary me-2"
				data-testid="ManagementFormControlsDeleteHeader"
			>
				<div class="d-flex flex-row align-items-center">
					<i class="fa-light fa-trash pe-2 text-danger"></i>
					<p i18n class="text-danger">Poista</p>
				</div>
			</button>
		}

		@if (showSubmit()) {
			<button
				(click)="updateEntity()"
				[disabled]="!updateActive()"
				type="submit"
				class="btn btn-success"
				data-testid="ManagementFormControlsSubmitHeader"
			>
				<div class="d-flex flex-row align-items-center">
					<i class="fa-light fa-save pe-2"></i>
					<p i18n>Tallenna</p>
				</div>
			</button>
		}
	</div>
}
