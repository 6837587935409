import { inject } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router'
import { ToastService, UserService } from '../../ngrx-store/entity-services'
import { firstValueFrom, map } from 'rxjs'
import { AdminType, User } from '../../ngrx-store/user/user.model'

export const adminGuard: CanActivateFn = async (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Promise<boolean> => {
	const toastService = inject(ToastService)
	const service = inject(UserService)
	const router = inject(Router)
	const allowedAdminRoles: AdminType[] = [
		AdminType.Operations,
		AdminType.Technical,
	]

	if (service.attributes() === null) {
		// Get user if the user navigates directly to the app with a link.
		service.getCurrentUser()
		// set user loading, so the filter does not pass after user is fetched
		service.setLoading()
	}

	const result = await firstValueFrom(
		service.getCurrentUser().pipe(
			map((user) => {
				if (!hasAdminAccess(allowedAdminRoles, user)) {
					toastService.showError(
						$localize`Käyttäjälläsi ei ole oikeuksia kyseiselle sivulle.`
					)
					router.navigateByUrl('')
					return false
				}
				return true
			})
		)
	)
	return result
}

export const operationsAdminGuard: CanActivateFn = async (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Promise<boolean> => {
	const toastService = inject(ToastService)
	const service = inject(UserService)
	const router = inject(Router)
	const allowedAdminRoles: AdminType[] = [AdminType.Operations]

	if (service.attributes() === null) {
		// Get user if the user navigates directly to the app with a link.
		service.getCurrentUser()
		// set user loading, so the filter does not pass after user is fetched
		service.setLoading()
	}

	const result = await firstValueFrom(
		service.getCurrentUser().pipe(
			map((user) => {
				if (!hasAdminAccess(allowedAdminRoles, user)) {
					toastService.showError(
						$localize`Käyttäjälläsi ei ole oikeuksia kyseiselle sivulle.`
					)
					router.navigateByUrl('')
					return false
				}
				return true
			})
		)
	)
	return result
}

export function hasAdminAccess(adminTypes: AdminType[], user: User): boolean {
	let allowAccess = false
	if (adminTypes.length === 0) {
		return true
	}
	for (const adminType of adminTypes) {
		if (user.adminTypes?.includes(adminType)) {
			allowAccess = true
			break
		}
	}
	return allowAccess
}
