import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { MbscSelectModule } from '@mobiscroll/angular'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import '@angular/localize/init'
import { filter, forkJoin, map, Subscription, take } from 'rxjs'
import {
	WorktimeReasonService,
	WorkShiftService,
	WorktimeGroupService,
} from '../../../../../core/ngrx-store/entity-services'
import {
	WorkShift,
	WorktimeGroup,
	WorktimeReason,
} from '../../../../../core/ngrx-store/models'
import { EventTypesToWorktimeReasons } from '../../../../../core/ngrx-store/event-type/event-type.model'
import { ManagementFormService } from '../../../../../features/management/management-form.service'
import { SortUtils } from '../../../../../core/utils/sort-utils'

export interface WorkshiftsWorktimeReasons {
	worktimeGroup: WorktimeGroup | undefined
	workShift: WorkShift
	worktimeReasons: WorktimeReason[]
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		CommonModule,
		MbscSelectModule,
		MatSelectModule,
		MatAutocompleteModule,
	],
	selector: 'kk-event-type-worktime-reason-mapping',
	templateUrl: './event-type-worktime-reason-mapping.html',
	styleUrls: ['./event-type-worktime-reason-mapping.scss'],
})
export class EventTypeWorktimeReasonMappingComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit, AfterViewInit, OnDestroy
{
	constructor(
		private readonly workshiftService: WorkShiftService,
		private readonly worktimeReasonService: WorktimeReasonService,
		private readonly managementFormService: ManagementFormService,
		private readonly worktimeGroupService: WorktimeGroupService
	) {
		super()
	}

	header = $localize`Työvuorojen syykoodit`
	placeholder = $localize`Valitse kirjauslajille työvuoron syykoodi`
	eventTypeId: number
	eventTypesToWorktimeReasons: EventTypesToWorktimeReasons[] = []
	workshiftsWorktimeReasons: WorkshiftsWorktimeReasons[] = []
	formGroup: FormGroup
	subscriptions = new Subscription()

	ngOnInit(): void {
		this.eventTypeId = this.model.id
		this.eventTypesToWorktimeReasons = this.model.eventTypesToWorktimeReasons
		this.mapWorkshiftsToWorktimeReasons()
		this.initializeFormGroup()
	}

	ngAfterViewInit(): void {
		this.sortWorkshiftsWorktimeReasons()
		this.setSelections()
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe()
	}

	/*
	 * Maps workshifts to their corresponding worktime reasons
	 */
	mapWorkshiftsToWorktimeReasons(): void {
		const workshiftSubscription = this.workshiftService.entities$
			.pipe(take(1))
			.subscribe((workshifts) => {
				workshifts.forEach((workshift) => {
					this.addWorktimeReasonsForWorkshift(workshift)
				})
			})

		this.subscriptions.add(workshiftSubscription)
	}

	/*
	 * Adds worktime reasons for a specific workshift
	 */
	private addWorktimeReasonsForWorkshift(workshift: WorkShift): void {
		const subscription = forkJoin({
			worktimeReasons: this.worktimeReasonService.entities$.pipe(
				take(1),
				map((worktimeReasons) =>
					worktimeReasons.filter(
						(reason) => reason.workshiftId === workshift.id
					)
				)
			),
			worktimeGroups: this.worktimeGroupService.entities$.pipe(
				filter((entities) => entities.length > 0),
				take(1)
			),
		}).subscribe({
			next: ({ worktimeReasons, worktimeGroups }) => {
				this.workshiftsWorktimeReasons.push({
					workShift: workshift,
					worktimeReasons: worktimeReasons,
					worktimeGroup: worktimeGroups.find(
						(group) => group.id === workshift.worktimeGroupId
					),
				})
			},
		})

		this.subscriptions.add(subscription)
	}

	/*
	 * Sorts the workshifts and worktime reasons
	 * First by worktime group name, then by workshift name
	 */
	sortWorkshiftsWorktimeReasons(): void {
		this.workshiftsWorktimeReasons.sort((a, b) => {
			const groupComparison = SortUtils.compareStrings(
				a.worktimeGroup?.name,
				b.worktimeGroup?.name
			)
			if (groupComparison !== 0) {
				return groupComparison
			}

			return SortUtils.compareStrings(a.workShift.name, b.workShift.name)
		})
	}

	/*
	 * Updates the model entries
	 */
	updateModelEntries(workshiftId: number, reasonId: number): void {
		const eventTypesToWorktimeReasons = this.eventTypesToWorktimeReasons.find(
			(item) => item.worktimeRegulationId === workshiftId
		)
		if (eventTypesToWorktimeReasons) {
			eventTypesToWorktimeReasons.worktimeReasonId = reasonId
		} else {
			this.eventTypesToWorktimeReasons.push({
				id: null,
				eventTypeId: this.eventTypeId,
				worktimeRegulationId: workshiftId,
				worktimeReasonId: reasonId,
			})
		}
		this.updateUnsavedEdits()
	}

	/*
	 * Sets the form selections from model
	 */
	setSelections(): void {
		this.eventTypesToWorktimeReasons.forEach((item) => {
			this.setFormValue(item.worktimeRegulationId, item.worktimeReasonId)
		})
	}

	/*
	 * Sets a form control
	 */
	setFormValue(workshiftId: number, reasonId: number): void {
		const formControl = this.formGroup.get(
			workshiftId.toString()
		) as FormControl

		formControl?.setValue(reasonId)
	}

	/*
	 * Updates the unsaved edits
	 */
	updateUnsavedEdits() {
		this.model.eventTypesToWorktimeReasons = this.eventTypesToWorktimeReasons
		this.managementFormService.updateFormValue(this.model)
		this.managementFormService.updateUnsavedEdits(true)
	}

	/*
	 * Initializes the form group
	 */
	initializeFormGroup(): void {
		this.formGroup = new FormGroup({})
	}

	/*
	 * Creates a form control
	 */
	createFormControl(id: number): FormControl {
		const control = new FormControl()
		this.formGroup.addControl(id.toString(), control)
		return control
	}

	/*
	 * Gets a form control
	 */
	getForm(id: number): FormControl {
		const form = this.formGroup.get(id.toString()) as FormControl
		return form
	}
}
