import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	QueryParams,
} from '@ngrx/data'
import { WorktimeGroupHistory } from './worktime-group-history.model'
import { Observable, shareReplay } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { WorktimeGroupHistoryToPast } from './worktime-group-history-mapping.model'

@Injectable()
export class WorktimeGroupHistoryService extends EntityCollectionServiceBase<WorktimeGroupHistory> {
	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('WorktimeGroupHistory', serviceElementsFactory)
	}

	override loadWithQuery(
		queryParams: QueryParams | string,
		options?: EntityActionOptions
	): Observable<WorktimeGroupHistory[]> {
		this.clearCache()
		return super.loadWithQuery(queryParams, options)
	}

	/**
	 * Get data that needs to be mapped on worktime group change to past
	 */
	public worktimeGroupHistoryToPast(
		personId: number,
		groupId: number,
		changeTime: Date
	): Observable<WorktimeGroupHistoryToPast> {
		return this.http
			.get<WorktimeGroupHistoryToPast>(
				'worktime-group-history/getWorktimeGroupHistoryToPastMapping/' +
					personId +
					'/' +
					groupId +
					'/' +
					changeTime.toISOString()
			)
			.pipe(shareReplay())
	}

	public setWorktimeGroupHistoryToPast(
		model: Partial<WorktimeGroupHistoryToPast>
	): Observable<WorktimeGroupHistory> {
		return this.http
			.post<WorktimeGroupHistory>(
				'worktime-group-history/setWorktimeGroupHistoryToPast',
				model
			)
			.pipe(shareReplay())
	}
}
