import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormDataRestorationService } from './form-data-restoration.service'

@Component({
	selector: 'kk-unsaved-form-modal',
	standalone: true,
	template: `
		<div class="modal-header p-3 pb-3 d-flex justify-content-between">
			<h2 class="modal-title" id="modal-basic-title" i18n>
				Tallentamaton lomake
			</h2>
			<button
				type="button"
				class="btn kk-btn-transparent"
				aria-label="Sulje"
				i18n-aria-label
				(click)="activeModal.dismiss('Cross click')"
			>
				<i class="fa-light fa-xmark"></i>
			</button>
		</div>
		<div class="modal-body px-3 py-0">
			<p i18n>
				Sinulla on tallentamaton lomake. Haluatko jatkaa lomakkeen täyttämistä
				vai hylätäänkö muutokset?
			</p>
		</div>
		<div class="modal-footer px-3 pb-2">
			<button type="button" class="btn btn-secondary" (click)="cancel()" i18n>
				Hylkää muutokset
			</button>
			<button
				type="submit"
				class="btn btn-success"
				(click)="continue()"
				i18n
				ngbAutofocus
			>
				Jatka täyttämistä
			</button>
		</div>
	`,
})
export class UnsavedFormModalComponent {
	constructor(
		public activeModal: NgbActiveModal,
		private formDataRestorationService: FormDataRestorationService
	) {}

	cancel() {
		this.activeModal.close('Close click')
		this.formDataRestorationService.discardData()
	}

	continue() {
		this.activeModal.close('Submit')
		this.formDataRestorationService.restoreData()
	}
}
