export enum AdminType {
	Operations = 'Operations',
	Technical = 'Technical',
}

export interface User {
	id: number
	globalUserId: number
	shortname: string
	email: string
	gsm: string
	worktimeGroupId: number
	useTimeZone: string
	adminTypes: AdminType[]
}
