import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import {
	EventType,
	PersonAbsenceReason,
	WorktimeReason,
} from './event-type.model'
import { createSelector } from '@ngrx/store'
import { selectEventTypes } from './event-type.selectors'
import { selectRouterEntityId } from '../router/router.selectors'

const selectEventTypesForWorktimeGroup = createSelector(
	selectEventTypes,
	selectRouterEntityId,
	(eventTypes, entityId) =>
		eventTypes.filter(
			(item) =>
				item.worktimeGroups.length === 0 ||
				item.worktimeGroups.includes(Number(entityId))
		)
)

@Injectable()
export class EventTypeService extends EntityCollectionServiceBase<EventType> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('EventType', serviceElementsFactory)
	}

	/**
	 *
	 * @returns Event types for the activated person group in management view
	 */
	eventTypesForWorktimeGroup$ = this.store.select(
		selectEventTypesForWorktimeGroup
	)
}

@Injectable()
export class WorktimeReasonService extends EntityCollectionServiceBase<WorktimeReason> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('WorktimeReason', serviceElementsFactory)
	}
}

@Injectable()
export class PersonAbsenceReasonService extends EntityCollectionServiceBase<PersonAbsenceReason> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('PersonAbsenceReason', serviceElementsFactory)
	}
}
