import { CommonModule } from '@angular/common'
import {
	Component,
	Input,
	ChangeDetectionStrategy,
	OnChanges,
	SimpleChanges,
	Output,
	EventEmitter,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import { EntityType, SidebarItem } from '../../models'
import { LoadingDirective } from 'src/app/shared/directives/loading/loading.directive'
import {
	MatTreeModule,
	MatTreeFlattener,
	MatTreeFlatDataSource,
} from '@angular/material/tree'
import { FlatTreeControl } from '@angular/cdk/tree'

interface SidebarNode extends SidebarItem {
	expandable: boolean
	level: number
}

const entityTreeMaxItems = 12
const entityTreeMaxLevel = 5
@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule, LoadingDirective, MatTreeModule],
	selector: 'kk-management-entity-tree',
	styleUrls: ['./entity-tree.component.scss'],
	template: `
		<div
			class="d-flex flex-column h-100 justify-content-start overflow-y-auto pe-3"
			[kkLoading]="loading"
			data-testid="ManagementSidebarEntityTree"
		>
			<mat-tree
				[dataSource]="dataSource"
				[treeControl]="treeControl"
				class="list-group"
			>
				<mat-tree-node
					*matTreeNodeDef="let node"
					matTreeNodePadding
					[matTreeNodePaddingIndent]="treeNodePadding"
				>
					<!-- Give leaf nodes the same padding using invisible button -->
					<button class="invisible"></button>
					<ng-container
						*ngTemplateOutlet="entityLink; context: { node, isLeaf: true }"
					></ng-container>
				</mat-tree-node>

				<mat-tree-node
					*matTreeNodeDef="let node; when: hasChild"
					matTreeNodePadding
					[matTreeNodePaddingIndent]="treeNodePadding"
				>
					<div class="d-flex align-items-center align-items-stretch w-100">
						<button
							class="btn"
							mat-icon-button
							matTreeNodeToggle
							[attr.aria-label]="'Laajenna ' + (node.description ?? '')"
							i18n-aria-label
						>
							<i
								*ngIf="!treeControl.isExpanded(node)"
								class="fa-solid fa-chevron-right"
							></i>
							<i
								*ngIf="treeControl.isExpanded(node)"
								class="fa-solid fa-chevron-down"
							></i>
						</button>
						<ng-container
							*ngTemplateOutlet="entityLink; context: { node, isLeaf: false }"
						></ng-container>
					</div>
				</mat-tree-node>
			</mat-tree>
		</div>

		<ng-template #entityLink let-node="node" let-isLeaf="isLeaf">
			<a
				class="mat-tree-node"
				[class.active]="node.id === activeEntityId"
				[ngClass]="{ 'fw-bolder ': !isLeaf }"
				class="list-group-item list-group-item-action border-0 rounded-2"
				[routerLink]="['.', entityType?.path, node.id]"
				data-testid="ManagementSidebarEntityItem"
			>
				{{ node.title | async }}
			</a>
		</ng-template>
	`,
})
export class ManagementEntityTreeComponent implements OnChanges {
	public treeNodePadding = 15

	@Input() items: SidebarItem[]
	@Input() entityType: EntityType | null = null
	@Input() activeEntityId: string
	@Input() loading: boolean

	@Output() canCreateNew = new EventEmitter<boolean>()

	// Material Tree helpers for mapping the nested data for the component
	treeControl = new FlatTreeControl<SidebarNode>(
		(node) => node.level,
		(node) => node.expandable
	)
	treeFlattener = new MatTreeFlattener(
		this.transformer,
		(node) => node.level,
		(node) => node.expandable,
		(node) => node.children
	)
	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['items']) {
			this.dataSource.data = this.items
		}
		const canCreate = this.canCreateNewEntity(this.items)
		this.canCreateNew.emit(canCreate)

		const activeNode = this.treeControl.dataNodes.find(
			(node) => node.id === this.activeEntityId
		)

		if (activeNode) {
			if (activeNode.level >= entityTreeMaxLevel) {
				this.canCreateNew.emit(false)
			}
			this.expandParents(activeNode)
			this.treeControl.expand(activeNode)
		}
	}

	private transformer(item: SidebarItem, level: number) {
		return {
			...item,
			expandable: !!item.children && item.children.length > 0,
			level: level,
		}
	}

	hasChild = (_: number, node: SidebarNode) => node.expandable

	/**
	 * Expands this item and its ancestors all the way to the top
	 * @param node
	 * @returns
	 */
	private expandParents(node: SidebarNode) {
		const level = node.level
		const index = this.treeControl.dataNodes.indexOf(node) - 1

		if (level === 0) {
			return
		}

		for (let i = index; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i]

			if (currentNode.level < level) {
				this.treeControl.expand(currentNode)

				if (currentNode.level === 0) {
					break
				}
			}
		}
	}

	/**
	 * Checks if a new entity can be created based on the total count.
	 *
	 * @param items - The array of items.
	 * @returns True if a new entity can be created, false otherwise.
	 */
	private canCreateNewEntity(items: SidebarItem[]): boolean {
		if (items.length === 0) return true
		const totalCount = items[0]?.totalCount || 0
		return totalCount < entityTreeMaxItems
	}
}
