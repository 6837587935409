import { EntityType } from './models'
import { InjectionToken, inject } from '@angular/core'
import { APP_CONFIG_TOKEN } from 'src/app/core/utils/app-config'
import { Feature } from 'src/app/core/utils/features'
import { allEntityTypes } from './configurations/configurations'
import { hasAdminAccess } from 'src/app/core/guards/admin-guard/admin.guard'
import { UserService } from 'src/app/core/ngrx-store/entity-services'
import { toSignal } from '@angular/core/rxjs-interop'

export type EntityTypeMap = Record<string, EntityType>
export const ENTITY_TYPE_MAP_TOKEN = new InjectionToken<EntityTypeMap>(
	'entityTypeMap'
)
/**
 * Provides enabled entity types that pass configured `featureGate`s
 * @param featureFlags `FeatureFlags` used in the `AppConfig`'s `features` section
 * @param entityTypes `EntityType[]` All entity types in the application
 * @returns `Record<string, EntityType>` of all the enabled features, where the key is entity type's path
 */
export default function entityTypeProviderFactory(
	featureFlags = inject(APP_CONFIG_TOKEN).features ?? {},
	userService = inject(UserService),
	entityTypes: EntityType[] = allEntityTypes
): EntityTypeMap {
	userService.getCurrentUser()

	function featureGate(entityType: EntityType) {
		const requiredFeatures = entityType.featureGate
		if (!requiredFeatures) return true

		return requiredFeatures.every((feature: Feature) => !!featureFlags[feature])
	}

	function adminGate(entityType: EntityType) {
		const requiredAdminTypes = entityType.requiredAdminTypes
		if (!requiredAdminTypes || requiredAdminTypes.length === 0) return true

		const currentUser = toSignal(userService.getCurrentUser())()
		if (currentUser) return hasAdminAccess(requiredAdminTypes, currentUser)
		else return false
	}

	const types = entityTypes
		.filter(featureGate)
		.filter(adminGate)
		.reduce((acc, current) => ({ ...acc, [current.path]: current }), {})
	return types
}
