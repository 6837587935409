import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { RealizedWorkShift } from './realized-work-shift.model'
import { HttpClient } from '@angular/common/http'
import { Observable, shareReplay } from 'rxjs'
import { DateUtils } from '../../utils/date-utils'

@Injectable()
export class RealizedWorkShiftService extends EntityCollectionServiceBase<RealizedWorkShift> {
	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('RealizedWorkShift', serviceElementsFactory)
	}

	getRealizedWorkShift(
		realizedWorkshiftId: number
	): Observable<RealizedWorkShift> {
		return this.http
			.get<RealizedWorkShift>(`realized-work-shift/` + realizedWorkshiftId)
			.pipe(shareReplay())
	}

	getRealizedWorkShiftForTime(
		personId: number,
		time: Date,
		dateOnly: boolean
	): Observable<RealizedWorkShift> {
		if (dateOnly) {
			return this.http
				.get<RealizedWorkShift>(
					`realized-work-shift/getRealizedWorkShiftForDate/` +
						personId +
						'/' +
						DateUtils.formatDateWithoutTimezone(time)
				)
				.pipe(shareReplay())
		} else {
			return this.http
				.get<RealizedWorkShift>(
					`realized-work-shift/getRealizedWorkShiftForTime/` +
						personId +
						'/' +
						time.toISOString()
				)
				.pipe(shareReplay())
		}
	}
}
