import { Injectable, signal } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UnsavedEditsModalComponent } from './unsaved-edits-modal.component'

@Injectable({ providedIn: 'root' })
export class UnsavedEditsService {
	constructor(
		private router: Router,
		private modalService: NgbModal
	) {}
	/* Used for skipping the guard. Useful when user chooses to discard unsaved forms. */
	passGuard = signal(false)

	/**
	 * Used in unsavedEditsGuard. Do not use elsewhere.
	 */
	unsavedEdits = signal(false)

	/* Url where the user navigated before the guard fired. */
	targetUrl = signal('')

	setTargetUrl(value: string) {
		this.targetUrl.set(value)
	}

	setUnsavedEdits(value: boolean) {
		this.unsavedEdits.set(value)
	}

	async confirmExitPage() {
		this.passGuard.set(true)

		const url = this.targetUrl()
		await this.router.navigate([url])

		this.targetUrl.set('')
		this.passGuard.set(false)
		this.unsavedEdits.set(false)
	}

	open() {
		this.modalService.open(UnsavedEditsModalComponent, {
			size: 'md',
			animation: true,
			centered: true,
		})
	}
}
