export enum PersonRole {
	/**
	 * Administrator
	 */
	Administrator = 2,

	/**
	 * Supervisor
	 */
	Supervisor = 3,

	/**
	 * ProjectManager
	 */
	ProjectManager = 9,

	/**
	 * ProjectLeader
	 */
	ProjectLeader = 35,
}
