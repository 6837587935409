import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import {
	NavigationActionTiming,
	StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { managementRoutes } from 'src/app/features/management/management-routes'
import { dateGuard, viewGuard } from './features/calendar/calendar.guard'
import { adminGuard } from './core/guards/admin-guard/admin.guard'

export const routes: Routes = [
	{
		path: '',
		loadComponent: () =>
			import('src/app/features/home/home.component').then(
				(c) => c.HomeComponent
			),
	},
	{
		path: 'calendar',
		loadComponent: () =>
			import('src/app/features/calendar/calendar.component').then(
				(c) => c.CalendarComponent
			),
		canActivate: [dateGuard, viewGuard],
		runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
	},
	{
		path: 'playground',
		loadChildren: () =>
			import('src/app/features/playground/playground.module').then(
				(m) => m.PlaygroundModule
			),
	},
	{
		path: 'management',
		loadChildren: () =>
			import('src/app/features/management/management-routes').then(
				(x) => x.managementRoutes
			),
		canActivate: [adminGuard],
	},
	{
		path: 'settings',
		loadComponent: () =>
			import(
				'src/app/features/environment-select/environment-select.component'
			).then((m) => m.EnvironmentSelectComponent),
	},
	{
		path: 'news',
		loadComponent: () =>
			import('src/app/features/home/news/news.component').then(
				(m) => m.NewsComponent
			),
	},
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { bindToComponentInputs: true }),
		RouterModule.forChild(managementRoutes),
		StoreRouterConnectingModule.forRoot({
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
